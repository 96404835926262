<template>
  <div id="payment-list">
    <v-card>
      <v-card-title>Payments</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="periodTypeModel"
            placeholder="Range Type"
            :items="periodTypeItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details
            @change="onPeriodTypeChange"
          ></v-select>
        </v-col>

        <v-col
          v-show="showDateRange"
          cols="12"
          sm="4"
          class="pt-0 pb-0"
        >
          <v-menu
            ref="menuDateRangeFilter"
            v-model="menuDateRange"
            :return-value.sync="dateFilterModel"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            hide-details
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Dates Range"
                :prepend-icon="icons.mdiCalendar"
                readonly
                v-bind="attrs"
                hide-details
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="dateFilterModel"
              range
              no-title
              scrollable
              hide-details
            >
              <v-btn
                block
                outlined
                small
                dense
                @click="$refs.menuDateRangeFilter.save(dateFilterModel)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-autocomplete
            v-model="shopFilterModel"
            placeholder="Select Shop"
            :items="shopFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-autocomplete
            v-model="countryFilterModel"
            placeholder="Country"
            :items="countryFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
            @change="onCountryChange"
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-autocomplete
            v-model="paymentMethodFilterModel"
            placeholder="Payment Method"
            :items="paymentMethodFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
            :loading="loading"
          ></v-autocomplete>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="2"
        >
          <v-select
            v-model="statusFilterModel"
            placeholder="Select Status"
            :items="statusFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <v-select
            v-model="channelFilterModel"
            placeholder="Select Channel"
            :items="channelFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-btn
          ref="btnApplyFilters"
          color="primary"
          class="me-3 mb-4"
          :loading="loading"
          @click.prevent="applyFilters"
        >
          Apply Filters
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          type="reset"
          class="me-3 mb-4"
          @click.prevent="resetFilters"
        >
          Reset
        </v-btn>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-text-field
            v-model="searchFilterModel"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
            class="mb-4 me-3 searchBy"
            @keyup.enter="onSearch"
            @click:append="onSearch"
          >
          </v-text-field>

          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-0"
            :disabled="paymentItemsLength == 0"
            @click="downloadList"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        ref="paymentsTable"
        :headers="paymentsTableColumns"
        :items="paymentItems"
        :options.sync="options"
        :server-items-length="paymentItemsLength"
        :items-per-page="itemsPerPage"
        :loading="loading"
        show-current-page
        loading-text="Loading... Please wait"
        :footer-props="footerTableProps"
      >
        <template #[`item.paymentCode`]="{ item }">
          <router-link
            class="mr-3"
            :to="{
              name: 'processing-payment',
              params: { id: item.id },
            }"
            target="_blank"
          >
            {{ item.paymentCode }}
          </router-link>
        </template>

        <!-- Created At  -->
        <template #[`item.createdAt`]="{ item }">
          <span>{{ formatDateAndTime(item.createdAt) }}</span>
        </template>

        <template #[`item.paymentMethod`]="{ item }">
          <div class="table-row">
            {{ item.paymentMethod.name }}
          </div>
          <div class="table-row">
            <v-chip
              small
              :color="
                resolvePaymentMethodChannel(item.paymentMethod.channelCode)
              "
              :class="`${resolvePaymentMethodChannel(
                item.paymentMethod.channelCode
              )}--text`"
              class="v-chip-light-bg"
            >
              {{ item.paymentMethod.channelName }}
            </v-chip>
          </div>
          <div class="table-row">
            {{ item.paymentMethod.countryName }}
          </div>
        </template>

        <!-- Amount -->
        <template #[`item.amount`]="{ item }">
          <span
            class="text-capitalize font-weight-semibold text--primary align-right"
          >{{ formatCurrency(item.amount) }} {{ item.currencyCode }}</span>
        </template>

        <!-- Amount -->
        <template #[`item.voucherInfo`]="{ item }">
          <span
            class="text-capitalize font-weight-semibold text--primary align-right"
          >{{ formatCurrency(item.voucherInfo.amount) }} {{ item.voucherInfo.currency }}</span>
        </template>

        <template #[`item.merchantDiscountRate`]="{ item }">
          <span>{{ resolvemerchantDiscountRate(item.merchantDiscountRate) }}</span>
        </template>

        <!-- Status -->
        <template #[`item.paymentStatus`]="{ item }">
          <v-chip
            small
            :color="resolveStatusVariant(item.paymentStatus.statusCode)"
            :class="`${resolveStatusVariant(
              item.paymentStatus.statusCode
            )}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.paymentStatus.statusCode }}
          </v-chip>
          <div class="notifiedAt">
            {{ formatDateAndTime(item.paymentStatus.statusUpdatedAt) }}
          </div>
        </template>

        <template #[`item.customer`]="{ item }">
          <div>{{ item.customer.email }}</div>
          <div>
            {{ `${item.customer.firstName} ${item.customer.lastName}` }}
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <router-link
            :to="{
              name: 'processing-payment',
              params: { id: item.id },
            }"
          >
            <v-icon> {{ icons.mdiMagnify }} </v-icon>
          </router-link>
        </template>

      </v-data-table>
    </v-card>
    <v-toast ref="vtoast"></v-toast>
  </div>
</template>

<script>
import {
  formatDate,
  formatDateAndTime,
  formatCurrency,
} from '@core/utils/filter'
import {
  mdiExportVariant,
  mdiPlus,
  mdiCalendar,
  mdiMagnify,
  mdiEyeOutline,

  mdiPageFirst,
  mdiPageLast,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import {
  computed, onMounted, onUnmounted, ref, watch,
} from '@vue/composition-api'
import store from '@/store'
import storeModule from './storeModule'
import periodType from '@/constants/period-type'
import paymentStatus from '@/constants/payment-status'
import paymentMethodChannel from '@/constants/channel-code'

import VToast from '@/components/VToast.vue'

export default {
  components: { 
    VToast,
  },
  setup() {
    const STORE_MODULE_NAME = 'processing-payments'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }

    const paymentsTableColumns = [
      {
        text: 'UID', value: 'paymentCode', sortable: false, width: 220,
      },
      {
        text: 'ORDER ID', value: 'orderId', sortable: false, width: 220,
      },
      {
        text: 'CREATED ON', value: 'createdAt', align: 'center', width: 150,
      },
      {
        text: 'SHOP',
        value: 'shopName',
        sortable: false,
        align: 'center',
        width: 250,
      },
      {
        text: 'AMOUNT',
        value: 'amount',
        sortable: false,
        align: 'center',
        width: 150,
      },
      {
        text: 'BILLING AMOUNT',
        value: 'voucherInfo',
        sortable: false,
        align: 'center',
        width: 150,
      },
      {
        text: 'RATE DETAILS',
        value: 'merchantDiscountRate',
        sortable: false,
        align: 'center',
        width: 150,
      },
      {
        text: 'PAYMENT METHOD',
        value: 'paymentMethod',
        sortable: false,
        align: 'center',
        width: 150,
      },
      {
        text: 'STATUS',
        value: 'paymentStatus',
        sortable: false,
        align: 'center',
        width: 180,
      },
      {
        text: 'CUSTOMER',
        value: 'customer',
        sortable: false,
        align: 'center',
        width: 100,
      },
      {
        text: 'DESCRIPTION',
        value: 'description',
        sortable: false,
        align: 'center',
        width: 200,
      },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]
    const vtoast = ref({})

    const showDateRange = ref(false)

    const paymentItems = ref([])
    const paymentItemsLength = ref(0)

    const searchFilterModel = ref('')

    const shopFilterModel = ref(null)
    const shopFilterItems = ref([])

    const statusFilterModel = ref(null)
    const statusFilterItems = ref([])

    const channelFilterModel = ref(null)
    const channelFilterItems = ref([])

    const countryFilterModel = ref(null)
    const countryFilterItems = ref([])

    const paymentMethodFilterModel = ref(null)
    const paymentMethodFilterItems = ref([])

    const dateFilterModel = ref([
      new Date().toISOString().substring(0, 10),
      new Date().toISOString().substring(0, 10),
    ])

    const periodTypeItems = ref([])
    const periodTypeModel = ref(null)

    const loading = ref(false)

    const options = ref({
      sortBy: ['p.createdAt'],
      sortDesc: [true],
    })

    const footerTableProps = {
      showFirstLastPage: true,
      itemsPerPageOptions: [10, 20, 50, 100],
      showCurrentPage: true,
      firstIcon: mdiPageFirst,
      lastIcon: mdiPageLast,
      prevIcon: mdiChevronLeft,
      nextIcon: mdiChevronRight,
    }

    const itemsPerPage = ref(footerTableProps[0])

    const menuDateRange = ref(false)

    const fetchList = () => {
      loading.value = true
      store
        .dispatch('processing-payments/fetchList', {
          search: searchFilterModel.value,
          options: options.value,
          statusCode: statusFilterModel.value,
          countryCode: countryFilterModel.value,
          legacyCode: paymentMethodFilterModel.value,
          shopId: shopFilterModel.value,
          channelCode: channelFilterModel.value,
          periodType: periodTypeModel.value,
          from: dateFilterModel.value[0],
          to: dateFilterModel.value[1],
        })
        .then(response => {
          const { records, count } = response.data
          paymentItems.value = records
          paymentItemsLength.value = count
        })
        .catch(error => {
          vtoast.value.show(error.response.data.errors, 'error')
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onSearch = () => {
      if (searchFilterModel.value === '') return
      fetchList()
    }

    const fetchConfig = () => {
      loading.value = true
      store
        .dispatch('processing-payments/fetchConfig')
        .then(response => {
          const config = response.data
          shopFilterItems.value = config.shops
          statusFilterItems.value = config.paymentStatus
          channelFilterItems.value = config.channels
          countryFilterItems.value = config.countries
          paymentMethodFilterItems.value = config.paymentMethods
          periodTypeItems.value = config.periodTypes
          periodTypeModel.value = periodTypeItems.value.find(
            x => x.value === periodType.DAY,
          ).value
        })
        .catch(error => {
          vtoast.value.show(error.response.data.errors, 'error')
        })
        .finally(() => {
          loading.value = false
        })
    }

    const downloadList = () => {
      store
        .dispatch('processing-payments/export', {
          search: searchFilterModel.value,
          options: options.value,
          statusCode: statusFilterModel.value,
          countryCode: countryFilterModel.value,
          legacyCode: paymentMethodFilterModel.value,
          shopId: shopFilterModel.value,
          channelCode: channelFilterModel.value,
          periodType: periodTypeModel.value,
          from: dateFilterModel.value[0],
          to: dateFilterModel.value[1],
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          const fileName = response.headers.filename
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch(error => {
          vtoast.value.show(error.response.data.errors, 'error')
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onPeriodTypeChange = () => {
      showDateRange.value = periodTypeModel.value === periodType.CUSTOM
    }

    const onCountryChange = code => {
      loading.value = true
      store
        .dispatch('processing-payments/fetchPaymentMethodsByCountryConfig', {
          countryCode: code,
        })
        .then(response => {
          paymentMethodFilterItems.value = response.data
        })
        .catch(error => {
          vtoast.value.show(error.response.data.errors, 'error')
        })
        .finally(() => {
          loading.value = false
        })
    }

    watch([options], () => {
      fetchList()
    })

    const updateDataTableRow = row => {
      paymentItems.value.find(
        x => x.settlementCode === row.value.settlementCode,
      ).value = row.value
    }

    const applyFilters = () => {
      options.value.page = 1
      searchFilterModel.value = null
      fetchList()
    }

    const resetFilters = () => {
      searchFilterModel.value = ''
      shopFilterModel.value = null
      statusFilterModel.value = null
      channelFilterModel.value = null
      periodTypeModel.value = periodTypeItems.value.find(x => x.value === periodType.DAY).value
      showDateRange.value = false
      dateFilterModel.value = [
        new Date().toISOString().substring(0, 10),
        new Date().toISOString().substring(0, 10),
      ]
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*
    const resolveStatusVariant = status => {
      switch (status) {
        case paymentStatus.PENDING:
          return 'payment-status--pending'
        case paymentStatus.PAID:
          return 'payment-status--paid'
        case paymentStatus.EXPIRED:
          return 'payment-status--expired'
        case paymentStatus.FAILED:
          return 'payment-status--failed'
        default:
          return 'payment-status--pending'
      }
    }

    const resolvePaymentMethodChannel = channelCode => {
      if (channelCode === paymentMethodChannel.CASH) return 'success'
      if (channelCode === paymentMethodChannel.ONLINE) return 'warning'
      if (channelCode === paymentMethodChannel.CRYPTO) return 'primary'
      if (channelCode === paymentMethodChannel.CREDIT_CARD) return 'error'

      return 'success'
    }

    const resolvemerchantDiscountRate = merchantDiscountRate => {
      let html = ''
      if (merchantDiscountRate) {
        if (merchantDiscountRate.minRateDiscount > 0) {
          html += `$ ${formatCurrency(merchantDiscountRate.minRateDiscount)} (Min rate)`
        } else {
          const rateDetail = merchantDiscountRate.fixed > 0 ? `(${formatCurrency(merchantDiscountRate.percentage)}% + $ ${formatCurrency(merchantDiscountRate.fixed)})` : `(${formatCurrency(merchantDiscountRate.percentage)}%)`
          html += merchantDiscountRate.totalDiscount > 0 ? `$ ${formatCurrency(merchantDiscountRate.totalDiscount)} ${rateDetail}` : ''
        }
      }

      return html
    }

    const dateRangeText = computed(() => dateFilterModel.value.join(' ~ '))

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    onMounted(() => {
      fetchConfig()
    })

    return {
      dateRangeText,
      formatDateAndTime,
      formatDate,
      formatCurrency,
      paymentItems,
      paymentItemsLength,
      paymentsTableColumns,
      footerTableProps,
      itemsPerPage,
      searchFilterModel,
      shopFilterItems,
      shopFilterModel,
      statusFilterModel,
      statusFilterItems,
      channelFilterModel,
      channelFilterItems,
      countryFilterModel,
      countryFilterItems,
      paymentMethodFilterModel,
      paymentMethodFilterItems,
      periodTypeModel,
      periodTypeItems,
      dateFilterModel,
      menuDateRange,
      loading,
      options,
      showDateRange,
      resolvemerchantDiscountRate,
      applyFilters,
      resetFilters,
      fetchList,
      fetchConfig,
      onSearch,
      downloadList,
      updateDataTableRow,
      resolveStatusVariant,
      resolvePaymentMethodChannel,
      onPeriodTypeChange,
      onCountryChange,
      vtoast,

      // icons
      icons: {
        mdiPlus,
        mdiExportVariant,
        mdiCalendar,
        mdiMagnify,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style scoped>
.datePicker {
  margin-top: -5px;
}
.notifiedAt {
  font-size: 11px;
  padding: 5px;
}
.table-row {
  padding-top: 5px;
  padding-bottom: 5px;
}
.searchBy {
  width: 380px;
}
</style>
